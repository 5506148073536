.difHolder {
  position: fixed;
  left: 0;
  top: 0;
  width: 150px;
  height: 25px;
}

.difBar {
  display: block;
  height: 100%;
  transition: all 0.5s;
  border-radius: 7px;
}

.textHolder {
  text-align: center;
  display: block;
  color: white;
  font-weight: bold;
}

body {
  margin: 0;
  font-family: "opendys", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "opendys", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

@font-face {
  font-family: "vayle_demonic";
  src: url("./res/fonts/vayle_demonic.eot");
  src: url("./res/fonts/vayle_demonic.eot?#iefix") format("embedded-opentype"),
    url("./res/fonts/vayle_demonic.woff2") format("woff2"),
    url("./res/fonts/vayle_demonic.woff") format("woff"),
    url("./res/fonts/vayle_demonic.ttf") format("truetype"),
    url("./res/fonts/vayle_demonic.svg#vayle_demonicregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "seagram";
  src: url("./res/fonts/seagram-webfont.eot");
  src: url("./res/fonts/seagram-webfont.eot?#iefix") format("embedded-opentype"),
    url("./res/fonts/seagram-webfont.woff2") format("woff2"),
    url("./res/fonts/seagram-webfont.woff") format("woff"),
    url("./res/fonts/seagram-webfont.ttf") format("truetype"),
    url("./res/fonts/seagram-webfont.svg#seagram_tfbregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "opendys";
  src: url("./res/fonts/opendyslexic-regular.eot");
  src: url("./res/fonts/opendyslexic-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./res/fonts/opendyslexic-regular.woff2") format("woff2"),
    url("./res/fonts/opendyslexic-regular.woff") format("woff"),
    url("./res/fonts/opendyslexic-regular.ttf") format("truetype"),
    url("./res/fonts/opendyslexic-regular.svg#opendyslexicregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.demonText {
  display: block;
  padding: 50px 50px 0px 50px;
  text-align: center;
  color: grey;
}

.demonFont {
  font-family: "vayle_demonic";
  font-size: 8em;
  display: block;
  position: relative;
  margin-bottom: -30px;
}

.demonText b {
  color: darkgoldenrod;
}

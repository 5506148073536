body {
  background-image: url("https://images.clipartlogo.com/files/istock/previews/7659/76592093-bats-seamless-pattern-on-dark-grunge-background-illustration.jpg");
}

.App {
  padding: 0px;
  margin: 0px;
}

.room {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.room h1 {
  font-family: "Seagram";
  font-size: 4em;
  letter-spacing: 0.1em;
  color: rgb(127, 136, 221);
  padding-top: 30px;
  text-align: center;
  display: block;
}

.room .banner {
  display: block;
  margin: 0 auto;
  height: 400px;
  width: 100%;
  object-fit: contain;
}

.roomText {
  display: block;
  padding: 25px 25px 0px 25px;
  text-align: justify;
}

.secondaryText {
  display: block;
  padding: 50px 50px 0px 50px;
  text-align: center;
}

.descriptionText {
  font-style: italic;
  color: lightgrey;
  display: block;
  padding: 50px 50px 0px 50px;
  text-align: justify;
}

.readingText {
  display: block;
  padding: 50px 50px 0px 50px;
  text-align: left;
  font-style: italic;
}

.nextHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  justify-items: center;
  padding: 50px 0px 50px 0px;
}

.nextHolder img {
  height: 300px;
  width: 250px;
  object-fit: contain;
}

.nextHolder img.wide {
  height: 300px;
  width: 250px;
  object-fit: cover;
}

.entry {
  display: block;
  text-align: center;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  transition: all 0.5s;
  max-width: 300px;
  padding: 10px;
  border: 0px;
  background-color: rgb(180, 180, 180);
}

.entry:hover {
  background-color: orange;
}

.entry span {
  display: block;
  padding-top: 5px;
}

.roomHolder {
  position: relative;
  width: 100%;
  max-width: 1500px;
  min-height: 100vh;
  margin: 0 auto;
  color: white;
  background-color: rgb(50, 50, 50);
}

.inputHolder {
  padding-top: 50px;
  width: 100%;
  padding-bottom: 50px;
}
.inputLabel {
  display: block;
  margin: 10px;
  text-align: center;
  color: orange;
}
.inputField {
  margin: 10px auto;
  display: block;
  text-align: center;
}
.inputSubmit {
  margin: 10px auto;
  display: block;
  color: white;
  font-weight: bold;
  background-color: rgb(20, 20, 20);
  border-radius: 7px;
  border-color: rgb(80, 80, 80);
  border-width: 3px;
  border-style: solid;
  padding: 10px;
  transition: all 0.5s;
}

.inputSubmit:hover {
  background-color: orange;
  color: black;
  border-color: black;
  padding-left: 20px;
  padding-right: 20px;
}

.inputSubmit:active:hover {
  background-color: orangered;
}

.checkFail b {
  color: red;
}
.checkPass b {
  color: lime;
}

.error {
  display: block;
  margin: 10px;
  text-align: center;
  color: red;
}

.keyAcquire {
  padding: 20px;
}
.keyAcquire b {
  color: gold;
}
